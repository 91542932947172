import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { resetStores } from "../../../../../shared/api/stores/stores/stores.api";
import CfPrimaryTab from "../../../../../shared/components/common/CfPrimaryTab/CfPrimaryTab";
import CfPrimaryTabs from "../../../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs";

interface Props {
  setStoreMaterialType: (materialTypeId: string) => void;
}

const StoresTabs = ({ setStoreMaterialType }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [tabValue, setTabValue] = useState(TABS.fertilizers.id);

  useEffect(() => {
    const activeTab =
      orderedTabs.find((tab) =>
        location.pathname.toLowerCase().includes(tab.url),
      ) || TABS.fertilizers;

    setStoreMaterialType(activeTab.materialTypeId);
    setTabValue(activeTab.id);
  }, [location.pathname, setStoreMaterialType]);

  const handleChange = (event: never, value: keyof typeof TABS) => {
    setTabValue(value);
    dispatch(resetStores());
    history.push(`./${TABS[value].url}`);
    setStoreMaterialType(TABS[value].materialTypeId);
  };

  return (
    <CfPrimaryTabs centered onChange={handleChange} tabValue={tabValue}>
      {orderedTabs.map((tab) => (
        <CfPrimaryTab
          data-test={tab.id}
          key={tab.id}
          label={<FormattedMessage id={`common.tab.${tab.id}`} />}
          value={tab.id}
        />
      ))}
    </CfPrimaryTabs>
  );
};

export { StoresTabs };

export const TABS = {
  fertilizers: {
    url: "fertilizers",
    id: "fertilizers",
    materialTypeId: "FR",
  },
  chemistries: {
    url: "pors",
    id: "chemistries",
    materialTypeId: "CH",
  },
  seeds: {
    url: "seeds",
    id: "seeds",
    materialTypeId: "SD",
  },
};

const orderedTabs = [TABS.fertilizers, TABS.chemistries, TABS.seeds];
