import React, { useContext, useEffect, useState } from "react";

import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getParcelsToAdd } from "../shared/selectors/actions.selectors";

import {
  addInitParcelsToAdd,
  clearParcelsToAdd,
  fetchHarvestProduct,
} from "../shared/actions/actions.actions";

import { FORM_TITLE_LOCALIZATION, FORM_TYPES } from "./actionOther.constants";

import { getSeedsSuggestionsApi } from "../../../shared/api/agroevidence/catalogues/seeds/seeds.api";
import { useToggle } from "../../../shared/hooks/useToggle";
import LocalStorage from "../../../shared/services/LocalStorage.service";
import { ActionDetailHeader } from "../shared/components/ActionDetailHeader/ActionDetailHeader";
import { ActionDetailContext } from "../shared/containers/ActionDetail/ActionDetail";

import { initialEmptyValues } from "./actionOther.services";
import { ActionOtherFormWrapper } from "./ActionOtherFormWrapper";
import { CoreActionWarningMessage } from "./components/CoreActionWarningMessage";

import { ActionOtherFormValues, InitialParcelToAdd } from "./actionOther.types";
import { ActionsState } from "../../../reducers/actions.reducer.types";
import {
  ActionRevenueDetailTo,
  ActionSowingDetailTo,
  ActionOtherDetailTo,
  DeprecatedFarmTo,
  ParcelTo,
} from "../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  ngGoToActions: () => void;
  existingAction:
    | ActionRevenueDetailTo
    | ActionSowingDetailTo
    | ActionOtherDetailTo;
  isNew?: boolean;
  formType: string;
  initParcelIds: string[];
  farm: DeprecatedFarmTo;
}

const ActionOtherDetail = ({
  existingAction,
  farm,
  formType,
  initParcelIds,
  isNew = false,
  ngGoToActions,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialParcelsToAdd = useSelector((state: ActionsState) =>
    getParcelsToAdd("other", state),
  );
  const { handleStartIsSplitting, isSplitting } =
    useContext(ActionDetailContext);
  const { farmId } = useParams<{ farmId: string }>();

  const {
    on: isEditing,
    setOff: onEditingEnd,
    setOn: onEditingStart,
  } = useToggle(isNew);

  const [formState, setFormState] = useState({
    initialValues: initialEmptyValues,
    lsName: "",
    hasAddedInitialParcels: false,
  });

  const hasInitParcels = !!initParcelIds?.[0];

  useEffect(() => {
    if (formType === FORM_TYPES.MOWING) {
      dispatch(fetchHarvestProduct());
    }

    if (formType === FORM_TYPES.SOWING) {
      dispatch(getSeedsSuggestionsApi({ "include-deleted": true }));
    }

    const lsName = `form_state_${formType}_${farmId}`;
    const savedValuesFromPreviousInteraction =
      LocalStorage.loadFromLocalStorage(lsName);

    const newState: {
      lsName: string;
      formType: string;
      initialValues?: ActionOtherFormValues;
    } = {
      lsName,
      formType,
    };

    if (hasInitParcels) {
      dispatch(addInitParcelsToAdd(initParcelIds));
    }

    if (savedValuesFromPreviousInteraction && !hasInitParcels) {
      const cleanedValues = {
        ...savedValuesFromPreviousInteraction,
        date: moment(savedValuesFromPreviousInteraction.date),
      };
      newState.initialValues = cleanedValues;
    }

    setFormState((prevState) => ({
      ...prevState,
      ...newState,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasInitParcels) return;
    if (!initialParcelsToAdd.length) return;
    if (formState.hasAddedInitialParcels) return;

    setFormState((prevState) => ({
      ...prevState,
      hasAddedInitialParcels: true,
      initialValues: {
        ...prevState.initialValues,
        parcels: [
          ...prevState.initialValues.parcels,
          ...initialParcelsToAdd.map((p: ParcelTo) =>
            mapInitialParcelsToAdd(p),
          ),
        ],
      },
    }));
    dispatch(clearParcelsToAdd());
  }, [
    dispatch,
    formState.hasAddedInitialParcels,
    hasInitParcels,
    initialParcelsToAdd,
  ]);

  const mapInitialParcelsToAdd = (parcel: ParcelTo): InitialParcelToAdd => ({
    ...parcel,
    subtractableAreas: {
      absolute: [],
      boundary: [],
      water: [],
      boundaryChecked: 0,
      waterChecked: 0,
    },
    restrictedArea: 0,
    actionParcelTotalArea: parcel.area,
  });

  const handleResetForm = () => {
    setFormState((prevState) => ({
      ...prevState,
      initialValues: initialEmptyValues,
    }));
  };

  const onLsReset = () => LocalStorage.removeFromLocalStorage(formState.lsName);

  const onLsSave = (values: ActionOtherFormValues) => {
    LocalStorage.saveToLocalStorage(values, formState.lsName);
  };

  return (
    <div className={classes.wrapperStyle}>
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionDetailHeader
              handleStartIsSplitting={handleStartIsSplitting}
              isDisabled={isEditing || isSplitting}
              isDraft={!!existingAction?.isDraft}
              isExisting={!isNew}
              ngGoToActions={ngGoToActions}
              onClick={onEditingStart}
              title={FORM_TITLE_LOCALIZATION[formType]}
            />
            <Grid container justifyContent="center" spacing={2}>
              <Grid item lg={8} md={10} xs={12}>
                <Box className={classes.warningMessageContainer}>
                  <CoreActionWarningMessage action={existingAction} />
                </Box>
                <ActionOtherFormWrapper
                  countryCode={farm.customer.countryCode}
                  existingAction={existingAction}
                  formType={formType}
                  goToActions={ngGoToActions}
                  handleLsReset={onLsReset}
                  handleResetForm={handleResetForm}
                  handleSaveToLs={onLsSave}
                  initialValues={formState.initialValues}
                  isDraft={!!existingAction?.isDraft}
                  isEditing={isEditing}
                  isExisting={!isNew}
                  lsName={formState.lsName}
                  onEditingEnd={onEditingEnd}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export { ActionOtherDetail };

const useStyles = makeStyles({
  wrapperStyle: {
    position: "absolute",
    top: 56,
    left: 0,
    right: 0,
    bottom: 0,
  },
  container: {
    margin: 15,
    paddingBottom: 30,
  },
  warningMessageContainer: {
    margin: "10px 20px",
  },
});
