import React, { useContext, useMemo } from "react";

import { Formik, FormikErrors } from "formik";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { RSAAResultAction } from "redux-api-middleware";

import {
  FORM_TYPES,
  OTHER_ACTION_HARVEST_CODE,
  OTHER_ACTION_MOWING_CODE,
  OTHER_ACTION_SOWING_CODE,
} from "./actionOther.constants";

import {
  updateMowingActionApi,
  updateHarvestActionApi,
  updateSowingActionApi,
  updateOtherActionApi,
  createMowingActionApi,
  createHarvestActionApi,
  createSowingActionApi,
  createOtherActionApi,
} from "../../../shared/api/agroevidence/actions/actions.api";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useTypedIntl } from "../../../shared/hooks/useTypedIntl";
import LocalStorage from "../../../shared/services/LocalStorage.service";

import {
  mapOtherFormInitialValues,
  mapRequestBodyOtherActionTo,
} from "./actionOther.services";
import { ActionOtherForm } from "./ActionOtherForm";
import { filterOnlySownParcels } from "./helpers/others.helpers";

import { ActionOtherFormValues } from "./actionOther.types";
import {
  ActionHarvestCreateTo,
  ActionMowingCreateTo,
  ActionOtherCreateTo,
  ActionRevenueDetailTo,
  ActionSowingDetailTo,
  ActionOtherDetailTo,
  ActionSowingCreateTo,
} from "../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  countryCode: string;
  existingAction:
    | ActionRevenueDetailTo
    | ActionSowingDetailTo
    | ActionOtherDetailTo;
  formType: string;
  goToActions: () => void;
  handleLsReset: () => void;
  handleResetForm: () => void;
  handleSaveToLs: (values: ActionOtherFormValues) => void;
  initialValues: ActionOtherFormValues;
  isDraft: boolean;
  isEditing: boolean;
  isExisting: boolean;
  lsName: string;
  onEditingEnd: () => void;
}

const ActionOtherFormWrapper = ({
  countryCode,
  existingAction,
  formType,
  goToActions,
  handleLsReset,
  handleResetForm,
  handleSaveToLs,
  initialValues,
  isDraft,
  isEditing,
  isExisting,
  lsName,
  onEditingEnd,
}: Props) => {
  const { formatMessage } = useIntl();
  const { locale } = useTypedIntl();
  const dispatch = useDispatch();

  const showSnackbar = useContext(SnackbarContext);

  const validateForm = (values: ActionOtherFormValues) => {
    const errors: FormikErrors<ActionOtherFormValues> = {};
    if (!(values.parcels?.length > 0)) {
      errors.parcels = "error";
    }
    if (values.actionType === FORM_TYPES.SOWING && !values.seedId) {
      errors.seedId = "error";
    }
    return errors;
  };

  // TODO: Saving of newly created subtractable areas
  // When deleting of subtractable areas is ready on back end,
  // we can save the newly created subtractableAres that are applied on the parcels in this action.
  // This mechanism is already implemented in EPH form,
  // but here, we will save the new subtractable areas when we can also delete them.
  const saveAction = (values: ActionOtherFormValues) => {
    const { actionType } = values;

    let parcelsToSave = values.parcels;

    // Filter only sown parcels for harvet action
    if (actionType === OTHER_ACTION_HARVEST_CODE)
      parcelsToSave = filterOnlySownParcels(values.parcels) ?? [];

    const valuesToSave: ActionOtherFormValues = {
      ...values,
      parcels: [...parcelsToSave],
    };

    const data = mapRequestBodyOtherActionTo(valuesToSave, locale);

    switch (actionType) {
      case OTHER_ACTION_MOWING_CODE:
        (
          dispatch(createMowingActionApi(data)) as unknown as Promise<unknown>
        ).then((res: RSAAResultAction<ActionMowingCreateTo>) =>
          responseProcessing(res, true),
        );
        break;

      case OTHER_ACTION_HARVEST_CODE:
        (
          dispatch(createHarvestActionApi(data)) as unknown as Promise<unknown>
        ).then((res: RSAAResultAction<ActionHarvestCreateTo>) =>
          responseProcessing(res, true),
        );
        break;

      case OTHER_ACTION_SOWING_CODE:
        (
          dispatch(createSowingActionApi(data)) as unknown as Promise<unknown>
        ).then((res: RSAAResultAction<ActionSowingCreateTo>) =>
          responseProcessing(res, true),
        );
        break;

      default:
        (
          dispatch(createOtherActionApi(data)) as unknown as Promise<unknown>
        ).then((res: RSAAResultAction<ActionOtherCreateTo>) =>
          responseProcessing(res, true),
        );
    }
  };

  const updateAction = (values: ActionOtherFormValues) => {
    const { actionType } = values;

    const data = mapRequestBodyOtherActionTo(values, locale, existingAction);

    switch (actionType) {
      case OTHER_ACTION_MOWING_CODE:
        (
          dispatch(updateMowingActionApi(data)) as unknown as Promise<unknown>
        ).then((res: RSAAResultAction<ActionMowingCreateTo>) =>
          responseProcessing(res),
        );
        break;

      case OTHER_ACTION_HARVEST_CODE:
        (
          dispatch(updateHarvestActionApi(data)) as unknown as Promise<unknown>
        ).then((res: RSAAResultAction<ActionHarvestCreateTo>) =>
          responseProcessing(res),
        );
        break;

      case OTHER_ACTION_SOWING_CODE:
        (
          dispatch(updateSowingActionApi(data)) as unknown as Promise<unknown>
        ).then((res: RSAAResultAction<ActionSowingCreateTo>) =>
          responseProcessing(res),
        );
        break;

      default:
        (
          dispatch(updateOtherActionApi(data)) as unknown as Promise<unknown>
        ).then((res: RSAAResultAction<ActionOtherCreateTo>) =>
          responseProcessing(res),
        );
    }
  };

  const responseProcessing = (
    res: RSAAResultAction<unknown>,
    newAction = false,
  ) => {
    if (newAction) LocalStorage.removeFromLocalStorage(lsName);
    if (!res.error) {
      handleResetForm();
      if (newAction) onEditingEnd();
      goToActions();
    } else {
      showSnackbar({
        message: formatMessage({ id: "action.other.error" }),
        isError: true,
      });
    }
  };

  const handleSubmit = (values: ActionOtherFormValues) => {
    if (isExisting) {
      updateAction(values);
    } else {
      saveAction(values);
    }
  };

  const formikInitialValues = useMemo(() => {
    if (!existingAction) return initialValues;

    return mapOtherFormInitialValues(existingAction, locale);
  }, [existingAction, initialValues, locale]);

  return (
    <Formik
      enableReinitialize
      initialValues={formikInitialValues}
      onSubmit={handleSubmit}
      validate={validateForm}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(formikProps) => (
        <ActionOtherForm
          countryCode={countryCode}
          formType={formType}
          handleLsReset={handleLsReset}
          handleResetForm={handleResetForm}
          handleSaveToLs={handleSaveToLs}
          isDraft={isDraft}
          isEditing={isEditing}
          isExisting={isExisting}
          isSubmitting={formikProps.isSubmitting}
          onEditingEnd={onEditingEnd}
          values={formikProps.values as ActionOtherFormValues}
        />
      )}
    </Formik>
  );
};

export { ActionOtherFormWrapper };
