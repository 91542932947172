import React from "react";

import { Switch, Route } from "react-router-dom";

import { LANG_KEYS } from "../../../../../shared/lang/lang.constants";
import { FORM_TYPES } from "../../../ActionOthers/actionOther.constants";

import BaseContainer from "../../../../../shared/containers/BaseContainer/BaseContainer";
import Localization from "../../../../../shared/services/Localization.service";
import ActionEphDetail from "../../../ActionEph/ActionEphDetail";
import { ActionOtherDetail } from "../../../ActionOthers/ActionOtherDetail";
import actionsTranslationsCZ from "../../../lang/locale-cs-CZ.json";
import actionsTranslationsEL from "../../../lang/locale-el-GR.json";
import actionsTranslationsEN from "../../../lang/locale-en-US.json";
import actionsTranslationsES from "../../../lang/locale-es-ES.json";
import actionsTranslationsHU from "../../../lang/locale-hu-HU.json";
import actionsTranslationsPL from "../../../lang/locale-pl-PL.json";
import actionsTranslationsRO from "../../../lang/locale-ro-RO.json";
import ActionsList from "../../../list/containers/ActionsList/ActionsList";
import Vrf from "../../../vrf/containers/Vrf/Vrf";
import Vrs from "../../../vrs/containers/Vrs/Vrs";
import ActionDetail from "../ActionDetail/ActionDetail";

const actionsTranslations = {
  [LANG_KEYS.CZ]: actionsTranslationsCZ,
  [LANG_KEYS.EN]: actionsTranslationsEN,
  [LANG_KEYS.ES]: actionsTranslationsES,
  [LANG_KEYS.EL]: actionsTranslationsEL,
  [LANG_KEYS.RO]: actionsTranslationsRO,
  [LANG_KEYS.HU]: actionsTranslationsHU,
  [LANG_KEYS.PL]: actionsTranslationsPL,
};

function Actions({ ...props }) {
  return (
    <BaseContainer
      langId={props.langId}
      translations={Localization.mergeTranslations(actionsTranslations)}
    >
      <Switch>
        <Route
          exact
          path="/farm/:farmId/actions"
          render={(routerProps) => <ActionsList {...props} {...routerProps} />}
        />
        <Route
          path="/farm/:farmId/actions/eph"
          render={(routerProps) => (
            <ActionEphDetail {...props} {...routerProps} isNew />
          )}
        />
        <Route
          path="/farm/:farmId/actions/variable-fertilization"
          render={(routerProps) => <Vrf {...props} {...routerProps} />}
        />
        <Route
          path="/farm/:farmId/actions/variable-seeding"
          render={(routerProps) => <Vrs {...props} {...routerProps} />}
        />
        <Route
          path="/farm/:farmId/actions/other"
          render={(routerProps) => (
            <ActionOtherDetail
              {...props}
              {...routerProps}
              formType={FORM_TYPES.OTHER}
              isNew
            />
          )}
        />
        <Route
          path="/farm/:farmId/actions/mowing"
          render={(routerProps) => (
            <ActionOtherDetail
              {...props}
              {...routerProps}
              formType={FORM_TYPES.MOWING}
              isNew
            />
          )}
        />
        <Route
          path="/farm/:farmId/actions/sowing"
          render={(routerProps) => (
            <ActionOtherDetail
              {...props}
              {...routerProps}
              formType={FORM_TYPES.SOWING}
              isNew
            />
          )}
        />
        <Route
          path="/farm/:farmId/actions/harvest"
          render={(routerProps) => (
            <ActionOtherDetail
              {...props}
              {...routerProps}
              formType={FORM_TYPES.HARVEST}
              isNew
            />
          )}
        />
        <Route
          path="/farm/:farmId/actions/:actionId"
          render={(routerProps) => <ActionDetail {...props} {...routerProps} />}
        />
      </Switch>
    </BaseContainer>
  );
}

export default Actions;
