import React from "react";

import { Theme } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { makeStyles } from "@mui/styles";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { ReactNodeLike } from "prop-types";
import { FormattedMessage } from "react-intl";

import {
  DriveKeyTo,
  DriveValidationType,
  KeyType,
} from "../../../../generated/api/telematics";
import { useFarmIds } from "../../../../shared/api/client.utils";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import { DriveKeysFormValues } from "../DriveKeysForm";

import ApprovalWarning from "./ApprovalWarning";
import { driveKeysQuery } from "./DriveKey.api";

type Variant = "active" | "disabled";

type Props = {
  customClasses?: Record<string, string>;
  disabled?: boolean;
  fieldName: keyof DriveKeysFormValues;
  helperText?: NonNullable<ReactNodeLike> | null;
  keyType: KeyType;
  placeholder?: string;
  showApprovalWarning?: boolean;
  tooltipCode?: DriveValidationType;
  variant?: Variant;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    "& .MuiAutocomplete-endAdornment": {
      display: ({ variant }: { variant: Variant }) =>
        variant === "disabled" ? "none" : "initial",
    },
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
}));

const DriveKey = ({
  customClasses,
  disabled = false,
  fieldName,
  helperText,
  keyType,
  placeholder,
  showApprovalWarning = false,
  tooltipCode,
  variant = "active",
}: Props) => {
  const { errors, setFieldValue, values } =
    useFormikContext<DriveKeysFormValues>();
  const classes = useStyles({ variant });
  const keys = useSuspenseQuery({
    enabled: !disabled,
    ...driveKeysQuery({
      ...useFarmIds(),
      type: keyType,
    }),
  });

  const handleFilterOptions = createFilterOptions({
    stringify: ({ key, name }) => `${name} (${key})`,
  });

  const getSortingString = (item: DriveKeyTo) => `${item.key} ${item.name}`;

  return (
    <div className={classes.container}>
      <ApprovalWarning show={showApprovalWarning} tooltipCode={tooltipCode} />
      <CfFormControl>
        <CfAutocomplete
          classes={customClasses}
          defaultValues={values[fieldName]}
          disabled={disabled}
          error={!!errors[fieldName]}
          filterOptions={handleFilterOptions}
          getSortingString={getSortingString}
          helperText={helperText}
          isMultiple={false}
          multilineInput
          onChange={(value) => setFieldValue(fieldName, value)}
          placeholder={placeholder}
          suggestions={keys.data.data}
          getLabel={(option) =>
            option?.key ? `${option.key}\n${option.name ?? "-"}` : option.name
          }
          label={
            placeholder ? undefined : (
              <FormattedMessage
                id={`TelematicsAggregations.detail.keys.${fieldName}`}
              />
            )
          }
          renderOption={(option: DriveKeyTo) => (
            <div>
              <div>{option.key ?? "-"}</div>
              <div className={classes.secondaryText}>{option.name ?? "-"}</div>
            </div>
          )}
        />
      </CfFormControl>
    </div>
  );
};

export { DriveKey };
