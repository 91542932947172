import React, { useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { FieldArrayMetaProps } from "redux-form";

import { getMaterialsSuggestions } from "../../selectors/stores.selectors";

import {
  getMaterialsByName,
  resetMaterials,
} from "../../actions/stores.actions";

import { getSourceId } from "../../../../../catalogues/helpers/selectorHelpers";
import CfAutosuggest from "../../../../../shared/components/common/CfAutosuggest/CfAutosuggest";
import ListSelectorError from "../../../../../shared/components/form/ListSelectorError/ListSelectorError";
import { getLocalizedDateString } from "../../../../../shared/misc/timeHelpers";
import { AnyTodo } from "../../../../../types";

interface Props {
  meta: FieldArrayMetaProps;
  onSuggestionSelect: (value: AnyTodo) => void;
  onSuggestionReset: () => void;
  materialTypeId: string;
}

const MaterialSelector = ({
  materialTypeId,
  meta,
  onSuggestionReset,
  onSuggestionSelect,
}: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const suggestions = useSelector(getMaterialsSuggestions);

  const [disabled, setDisabled] = useState(true);

  const pathnameEnd = window.location.pathname.split("/").pop();

  const handleSuggestionReset = () => {
    setDisabled(true);
    onSuggestionReset();
  };

  const handleSuggestionSelect = (value: AnyTodo) => {
    setDisabled(false);
    onSuggestionSelect(value);
  };

  const getSuggestionValue = (sugg: AnyTodo) => {
    const usableUntil = (() => {
      switch (sugg.type) {
        case "FERTILIZER":
          return sugg.validTo ? getLocalizedDateString(sugg.validTo) : "-";
        case "CHEMISTRY":
          return sugg.validUntil
            ? getLocalizedDateString(sugg.validUntil)
            : "-";
        default:
          return null;
      }
    })();

    const catalogueType = sugg.catalogue?.type
      ? intl.formatMessage({ id: getSourceId(sugg.catalogue.type) })
      : null;

    const suggestionValue = [
      sugg.name,
      sugg.type !== "SEED" && catalogueType,
      sugg.type !== "SEED" && usableUntil,
    ]
      .filter(Boolean)
      .join(", ");

    return sugg.isFavorite ? `⭐ ${suggestionValue}` : suggestionValue;
  };

  const getSuggestions = (searchInput: string) => {
    dispatch(getMaterialsByName(searchInput, pathnameEnd));
  };

  const { error, submitFailed } = meta;

  const resolvePlaceholder = () => {
    switch (materialTypeId) {
      case "FR":
        return intl.formatMessage({ id: "Stores.fert-name" });
      case "CH":
        return intl.formatMessage({ id: "Stores.por-name" });
      case "SD":
        return intl.formatMessage({ id: "Stores.seed-name" });
      default:
        return intl.formatMessage({ id: "Stores.material-name" });
    }
  };

  return (
    <div>
      <CfAutosuggest
        autoFocus={true}
        clearInputOnAdornmentClick={true}
        clearInputOnSelect={false}
        clearSuggestions={() => dispatch(resetMaterials())}
        disabled={!disabled}
        getSuggestions={getSuggestions}
        getSuggestionValue={getSuggestionValue}
        isDebounced
        onAdornmentClick={handleSuggestionReset}
        onSuggestionSelected={handleSuggestionSelect}
        placeholder={resolvePlaceholder()}
        requiredLength={1}
        scrollable={true}
        scrollHeightMax={300}
        testData="material-selector"
        adornment={
          <IconButton disabled={disabled} size="large">
            <ClearIcon />
          </IconButton>
        }
        suggestions={suggestions.map((sugg) => ({
          ...sugg,
          title: intl.formatMessage({ id: sugg.title }),
        }))}
      />
      {submitFailed && error && (
        <ListSelectorError
          error={<FormattedMessage id="validation.required" />}
        />
      )}
    </div>
  );
};

export { MaterialSelector };
